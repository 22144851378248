import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO 
        title={`${post.frontmatter.title}`}
        description={`${post.excerpt}`}
      />

      <Container className="py-3 py-md-5">
        <Row className="mb-4 mb-md-5">
          <Col lg={{ size: 6 }} className="mx-auto">
            <h1 className="display-4">{post.frontmatter.title}</h1>
            <time dateTime={post.frontmatter.dateDefault} className="text-muted font-weight-light">Posted on {post.frontmatter.date}</time>
          </Col>
        </Row>

        <Row>
          <Col>
            <Img 
              fluid={post.frontmatter.cover.childImageSharp.fluid}
              alt={post.frontmatter.title} 
              className="mb-3 mb-lg-5" />
          </Col>
        </Row>

        <Row>
          <Col lg={{ size: 6, offset: 3 }} className="lead">
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </Col>
        </Row>

        {post.frontmatter.attachments && <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <hr />
            <h5>Attachments</h5>
            <ul>
              {post.frontmatter.attachments.map((file, index) => {
                return (
                  <li key={`pdf-${index}`}>
                    <a href={file.publicURL} download>
                      {file.name}
                    </a>
                  </li>
                )
              })}
            </ul>
          </Col>
        </Row>}
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        dateDefault: date
        cover {
          childImageSharp {
            fluid(maxWidth: 1100, maxHeight: 700, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        attachments {
          publicURL
          name
        }
      }
    }
  }
`